import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Visatask1 } from '../../../components/images/support/visa/Visatask1.generated';
import { Visatask2 } from '../../../components/images/support/visa/Visatask2.generated';
import { Visatask3 } from '../../../components/images/support/visa/Visatask3.generated';
import { Visatask4 } from '../../../components/images/support/visa/Visatask4.generated';
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "adding-a-visa-details-task-to-a-workflow"
    }}>{`Adding a Visa Details Task to a Workflow`}</h1>
    <ol>
      <li parentName="ol">{`Navigate to the `}<strong parentName="li">{`workflows`}</strong>{` menu and click `}<strong parentName="li">{`Create`}</strong></li>
    </ol>

    <Visatask1 mdxType="Visatask1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Add task`}</strong>{` and select the `}<strong parentName="li">{`Visa details`}</strong></li>
    </ol>

    <Visatask2 mdxType="Visatask2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Add a title to the workflow and click `}<strong parentName="li">{`Save`}</strong></li>
    </ol>

    <Visatask3 mdxType="Visatask3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`You can preview the questions by interacting with the form on the right.`}</li>
    </ol>

    <Visatask4 mdxType="Visatask4" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`You can now assign this workflow to an employee to collect their visa details. Once completed
by the employee you will be able to run a visa check. `}</li>
    </ol>
    <p>{`Learn how to complete a visa check on the next page.`}</p>

    <SupportFooter linkTo="/support/visa/completing-a-visa-check" linkText="Learn how to complete a visa check" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      